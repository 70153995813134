#projects {
    background: @black;
    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-left: 5px solid @red;
        border-right: 5px solid @red;
        .lg(border, none);

    
        .grid-wrap {
            margin-left: auto;
            margin-right: auto;
            width: 100%;        
        }
        .grid {
            width: 100%;
            .grid-sizer,
            .grid-item {
                width: 49.5%;
            }
            .grid-item {
                height: 50vh;
                margin-bottom: 1%;
                overflow: hidden;
                .sm(background, none);
                background: url('../img/loader.gif') center center;
                background-repeat: no-repeat;
                &:nth-last-child(1){
                    margin-bottom: 0;
                }
                &:nth-last-child(2){
                    margin-bottom: 0;
                }
                &::before {
                    content: '';
                    display: block;
                    position: relative;
                    z-index: 3;
                    margin: auto;
                    top: 100px;
                    width: 0%;
                    .lg(width, 100%);
                    height: 2px;
                    background: @red;
                    transition: width 300ms ease-in-out;
                }
                
                
                
                img {
                    max-width: none;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    transition: 500ms;
                    filter: grayscale(100%);
                }
                
                .title {
                    text-align: center;
                    position: absolute;
                    z-index: 3;
                    width: 100%;
                    padding-top: 30px;
                    height: 100px;
                    font-family: 'Titillium Web';
                    font-size: 40px;
                    .fsz(35px, 30px, 25px, 20px);
                    transform: translateY(-100%);
                    .lg(transform, translateY(0));
                    background: rgba(0,0,0,0.6);
                    transition: transform 250ms ease-in-out 300ms;
                }
                .project-view {
                    display: block;
                    text-align: center;
                    position: absolute;
                    z-index: 3;
                    bottom: 0;
                    width: 100%;
                    padding: 5% 0;
                    transform: translateY(100%);
                    .lg(transform, translateY(0));
                    transition: transform 250ms ease-in-out 300ms;
                    background: rgba(0,0,0,0.6);
                    .project-actions {
                        padding: 0;
                        list-style: none;
                        li {
                            font-size: 25px;
                            .lg(font-size, 22px);
                            .sm(font-size, 18px);
                            position: relative;
                            margin-top: 10px;
                            .fa {
                                position: absolute;
                                left: 20%;
                                font-size: 40px;
                                .lg(display, none);
                                color: @red;
                            }
                        }
                    }
                    .project-link {
                        color: #fff;
                        transition: 300ms ease-in-out;
                        font-size: 25px;
                        text-transform: uppercase;
                        .fsz(30px, 25px, 20px, 20px);
                        padding: 0 30px;
                        border:  3px solid #fff;
                        .lg-block({
                            color: @red;
                            border-color: @red;
                            background: rgba(0, 0, 0, 0.3);
                        });
                        &:hover{
                            color: @red;
                            border-color: @red;
                            background: rgba(0, 0, 0, 0.3);
                        }
                    }         
                }
                &:hover {
                    img {
                        filter: grayscale(0) saturate(150%);
                        transform: scale(1.3, 1.3);
                    }
                    .title {
                        transform: translateY(-2px);
                    }
                    .project-view {
                        transform: translateY(0);
                    }
                    &::before {
                        width: 100%;
                    }
                }
            }
            
            .grid-item--height2 {
                height: 70vh;
            }
            .grid-item--height3 {
                height: 80vh;
            }
            .gutter-sizer {
                width: 1%;
            }
        }
    }
}