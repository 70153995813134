.bg(@url: '', @color: transparent, @repeat: no-repeat, @x: center, @y: center) {
    background: @color url("@{url}") @repeat @x @y;
    background-size:cover;
}
.xl(@name, @value){
    @media (max-width:1200px){
        @{name} : @value;
    }
}
.lg(@name, @value){
    @media (max-width:992px){
        @{name} : @value;
    }
}
.md(@name, @value){
    @media (max-width:768px){
        @{name} : @value;
    }
}
.sm(@name, @value){
    @media (max-width:576px){
        @{name} : @value;
    }
}
.xl-block(@content){
    @media (max-width:1200px){
        @content();
    }
}
.lg-block(@content){
    @media (max-width:992px){
        @content();
    }
}
.md-block(@content){
    @media (max-width:768px){
        @content();
    }
}
.sm-block(@content){
    @media (max-width:576px){
        @content();
    }
}
.media-block(@content, @size){
    @media (max-width:@size){
        @content();
    }
}
.media(@name, @value,@size){
    @media (max-width:@size){
        @{name} : @value;
    }
}
.fsz(@lg,@md,@sm,@xsm){
    @media (max-width:992px){
        font-size : @lg;
    }
    @media (max-width:768px){
        font-size : @md;
    }
    @media (max-width:576px){
        font-size : @sm;
    }
    @media (max-width:400px){
        font-size : @xsm;
    }
};