header {
  height: 120vh;
}

header #particles-js {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 13;
  overflow-y: hidden;
}

header .container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header .menu-top {
  padding: 12px 0;
  position: absolute;
  top: 0;
  width: 100%;
}

header .menu-top .logo {
  position: relative;
  z-index: 15;
  display: inline-block;
  margin: 30px 0;
  height: 50px;
}

header .menu-top .logo img {
  max-width: none;
  height: 100%;
  width: auto;
}

header .menu-top .menu-slide {
  position: relative;
  z-index: 21;
  width: 55px;
  height: 55px;
  padding: 15px 0;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background 500ms ease-in;
}

header .menu-top .menu-slide:hover span {
  transform: rotate3d(0, 1, 0.1, 180deg);
}

header .menu-top .menu-slide:hover span:nth-child(1) {
  transition: 0.2s;
}

header .menu-top .menu-slide:hover span:nth-child(2) {
  transition: 0.3s;
}

header .menu-top .menu-slide:hover span:nth-child(3) {
  transition: 0.4s;
}

header .menu-top .menu-slide span {
  width: 35px;
  height: 2px;
  background: #fff;
}

header .content {
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 4;
}

header .content .textbox {
  position: relative;
  z-index: 12;
  text-align: right;
}

header .content .textbox h1 {
  color: #fff;
  text-shadow: 0px 5px 0px #d80027;
  font-size: 40px;
}

header .content .textbox h1 span {
  color: #d80027;
  text-shadow: 0px 5px 0px #fff;
}

header .content .social {
  position: absolute;
  top: 40%;
  left: 5%;
  z-index: 15;
  padding: 0;
  list-style: none;
}

header .content .social li {
  margin-bottom: 20px;
}

header .content .social li:last-child {
  margin: 0;
}

header .content .social li:hover .social-link span {
  transform: scale(1);
}

header .content .social li .social-link {
  color: rgba(216, 0, 39, 0.5);
  font-size: 25px;
  text-align: left;
}

header .content .social li .social-link span {
  font-size: 16px;
  display: inline-block;
  font-family: 'Titillium Web', sans-serif;
  transform: scale(0);
  transform-origin: -30% 50%;
  transition: 700ms ease-out;
  color: #fff;
}

header .menu-bottom {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 25;
  flex-wrap: wrap;
}

header .menu-bottom .menu-link-header {
  display: flex;
  align-items: center;
  width: 25%;
  height: 20vh;
  background: transparent;
  transform-style: preserve-3d;
  position: relative;
  text-align: center;
}

header .menu-bottom .menu-link-header::after {
  content: "";
  display: block;
  width: 2px;
  height: 50px;
  margin: auto;
  background: #fff;
}

header .menu-bottom .menu-link-header::before {
  content: "";
  display: block;
  width: 2px;
  height: 50px;
  margin: auto;
  background: #fff;
}

header .menu-bottom .menu-link-header .text {
  margin: auto;
  transform: translateZ(20px);
  color: #d80027;
  font-size: 35px;
  text-transform: uppercase;
}

@keyframes shake {
  0% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(5px);
  }

  50% {
    transform: translateX(0px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -10000px 5000px;
  }
}

@-webkit-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -10000px 5000px;
  }
}

@-moz-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -10000px 5000px;
  }
}

@-ms-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -10000px 5000px;
  }
}

.stars,
.twinkling {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.stars {
  background: #000 url(../img/stars.png) repeat top center;
  z-index: 0;
}

.twinkling {
  background: transparent url(../img/twinkling.png) repeat top center;
  z-index: 1;
  -moz-animation: move-twink-back 200s linear infinite;
  -ms-animation: move-twink-back 200s linear infinite;
  -o-animation: move-twink-back 200s linear infinite;
  -webkit-animation: move-twink-back 200s linear infinite;
  animation: move-twink-back 200s linear infinite;
}

#about {
  background: #d80027;
  min-height: 100vh;
}

#about .title {
  width: 100%;
  text-align: center;
}

#about h5 {
  font-family: 'Permanent Marker';
}

#about .content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#about .content .featurebox {
  width: 25%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#about .content .featurebox:hover .svg-wrap {
  animation: float 2s ease-in-out infinite;
}

#about .content .featurebox:hover h4 {
  text-shadow: 1px 3px 10px #000;
}

#about .content .featurebox .svg-wrap {
  width: 55%;
  margin: auto;
  margin-bottom: 10px;
}

#about .content .featurebox h4 {
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 700;
  margin-top: auto;
  margin-bottom: 0;
  transition: 1s;
}

#about .use .featurebox {
  margin-bottom: 40px;
}

#about .get .featurebox {
  transition-timing-function: linear;
}

#about .get .featurebox:hover h4 {
  color: #fff;
  text-shadow: 1px 3px 10px #000;
}

#about .get h4 {
  color: #000;
  transition: color 500ms ease-in-out, text-shadow 1s ease-in-out;
}

#about .svgtitle {
  display: none;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-20px);
  }

  100% {
    transform: translatey(0px);
  }
}

#projects {
  background: #000;
}

#projects .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-left: 5px solid #d80027;
  border-right: 5px solid #d80027;
}

#projects .content .grid-wrap {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

#projects .content .grid {
  width: 100%;
}

#projects .content .grid .grid-sizer,
#projects .content .grid .grid-item {
  width: 49.5%;
}

#projects .content .grid .grid-item {
  height: 50vh;
  margin-bottom: 1%;
  overflow: hidden;
  background: url('../img/loader.gif') center center;
  background-repeat: no-repeat;
}

#projects .content .grid .grid-item:nth-last-child(1) {
  margin-bottom: 0;
}

#projects .content .grid .grid-item:nth-last-child(2) {
  margin-bottom: 0;
}

#projects .content .grid .grid-item::before {
  content: '';
  display: block;
  position: relative;
  z-index: 3;
  margin: auto;
  top: 100px;
  width: 0%;
  height: 2px;
  background: #d80027;
  transition: width 300ms ease-in-out;
}

#projects .content .grid .grid-item img {
  max-width: none;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: 500ms;
  filter: grayscale(100%);
}

#projects .content .grid .grid-item .title {
  text-align: center;
  position: absolute;
  z-index: 3;
  width: 100%;
  padding-top: 30px;
  height: 100px;
  font-family: 'Titillium Web';
  font-size: 40px;
  transform: translateY(-100%);
  background: rgba(0, 0, 0, 0.6);
  transition: transform 250ms ease-in-out 300ms;
}

#projects .content .grid .grid-item .project-view {
  display: block;
  text-align: center;
  position: absolute;
  z-index: 3;
  bottom: 0;
  width: 100%;
  padding: 5% 0;
  transform: translateY(100%);
  transition: transform 250ms ease-in-out 300ms;
  background: rgba(0, 0, 0, 0.6);
}

#projects .content .grid .grid-item .project-view .project-actions {
  padding: 0;
  list-style: none;
}

#projects .content .grid .grid-item .project-view .project-actions li {
  font-size: 25px;
  position: relative;
  margin-top: 10px;
}

#projects .content .grid .grid-item .project-view .project-actions li .fa {
  position: absolute;
  left: 20%;
  font-size: 40px;
  color: #d80027;
}

#projects .content .grid .grid-item .project-view .project-link {
  color: #fff;
  transition: 300ms ease-in-out;
  font-size: 25px;
  text-transform: uppercase;
  padding: 0 30px;
  border: 3px solid #fff;
}

#projects .content .grid .grid-item .project-view .project-link:hover {
  color: #d80027;
  border-color: #d80027;
  background: rgba(0, 0, 0, 0.3);
}

#projects .content .grid .grid-item:hover img {
  filter: grayscale(0) saturate(150%);
  transform: scale(1.3, 1.3);
}

#projects .content .grid .grid-item:hover .title {
  transform: translateY(-2px);
}

#projects .content .grid .grid-item:hover .project-view {
  transform: translateY(0);
}

#projects .content .grid .grid-item:hover::before {
  width: 100%;
}

#projects .content .grid .grid-item--height2 {
  height: 70vh;
}

#projects .content .grid .grid-item--height3 {
  height: 80vh;
}

#projects .content .grid .gutter-sizer {
  width: 1%;
}

#resume {
  background: #d80027;
}

#resume .title {
  text-align: center;
}

#resume .content .center {
  margin: auto;
  width: 80%;
  padding: 30px 50px;
  background: #000;
  border-radius: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}

#resume .content .center .flipper {
  position: absolute;
  bottom: -5%;
  right: -5%;
  height: 100px;
  width: 100px;
  transform: rotate(15deg);
  background: #fff;
  z-index: 15;
  transition: 500ms ease-in-out;
  cursor: pointer;
}

#resume .content .center .flipper:hover {
  transform: rotate(0deg);
  bottom: -3%;
  right: -3%;
}

#resume .content .center .res-link {
  color: #fff;
}

#resume .content .center .res-link:hover {
  color: #d80027;
}

#resume .content .center img {
  position: absolute;
  top: 10%;
  right: 10%;
  width: 100px;
}

#resume .content .center .info {
  width: 100%;
  text-decoration: underline;
}

#resume .content .center .info h2 {
  text-transform: uppercase;
  font-size: 30px;
}

#resume .content .center .res {
  display: flex;
  justify-content: center;
  width: 50%;
  height: 60px;
  line-height: 60px;
  color: #fff;
  text-align: center;
  font-size: 25px;
  transition: color 500ms ease-in-out;
  z-index: 2;
}

#resume .content .center .res:hover {
  color: #d80027;
}

#resume .content .center h4 {
  display: inline-block;
  flex-basis: 50%;
  height: 80px;
  font-size: 22px;
}

#resume .content .center h4.res-title {
  font-size: 35px;
}

#resume .content .center h5 {
  display: inline-block;
  flex-basis: 50%;
  height: 80px;
  color: rgba(255, 255, 255, 0.7);
}

#contacts {
  background: #000;
  padding-bottom: 10px;
}

#contacts .title {
  text-align: center;
}

#contacts .title h5 {
  color: #fff;
  font-size: 15px;
}

#contacts .title h5 p {
  margin-bottom: 5px;
}

#contacts .title h5 .fa {
  font-size: 20px;
  color: #d80027;
}

#contacts .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#contacts .content .contacts-item {
  width: 33.33333333%;
  text-align: center;
  font-size: 70px;
}

#contacts .content .contacts-item a {
  color: #fff;
}

#contacts .content .contacts-item a .contact-info {
  display: block;
  margin: auto;
  border-radius: 30px;
  width: 0%;
  background: #fff;
  font-size: 25px;
  color: #000;
  transition: 700ms ease-in-out;
}

#contacts .content .contacts-item.lg {
  width: 50%;
  font-size: 100px;
  margin-bottom: 60px;
}

#contacts .content .contacts-item.lg .contact-info {
  transition: width 1500ms ease-in-out;
  font-size: 35px;
}

#contacts .content .contacts-item.lg :hover .fa {
  animation: bounce 1s infinite;
}

#contacts .content .contacts-item.sm:hover .contact-info {
  width: 90%;
}

#contacts .content .contacts-item.sm:hover .fa {
  animation: float 1s infinite;
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #d80027;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.loader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1999;
}

.loader .content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader .content .img {
  width: 200px;
}

.loader .content .text {
  margin: 20px 0;
  font-size: 50px;
  font-weight: 700;
  width: 150px;
  height: 150px;
  line-height: 145px;
  text-align: center;
  position: relative;
}

.loader .content .text .loadborder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 50%;
  border: 5px solid #fff;
  border-top-color: #d80027;
  border-bottom-color: #d80027;
  animation: rotating1 3s linear infinite;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #d80027;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  min-width: 320px;
  max-width: 100vw;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 900;
  font-size: 14px;
  color: #fff;
}

html,
body {
  overflow-x: hidden;
}

h1,
h3 {
  font-family: 'Press Start 2P', cursive;
}

h4,
h5 {
  font-family: 'Titillium Web';
}

h3 {
  font-size: 40px;
  text-shadow: 1px 3px 4px #000;
}

a:hover {
  text-decoration: none;
}

section {
  min-height: 100vh;
  padding: 100px 0 70px 0;
}

section .title + .content {
  width: 100%;
  margin: 80px 0;
}

.menu-slide-open {
  position: fixed;
  height: 100vh;
  top: 0;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.97);
}

.menu-slide-open .content {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-slide-open .content .close-menu {
  position: absolute;
  top: 5%;
  right: 5%;
  width: 25px;
  height: 25px;
  z-index: 1002;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-slide-open .content .close-menu span {
  display: block;
  width: 30px;
  height: 2px;
  background: #fff;
}

.menu-slide-open .content .close-menu span:nth-child(1) {
  transform: rotate(45deg);
  transform-origin: 50% 0;
}

.menu-slide-open .content .close-menu span:nth-child(2) {
  transform: rotate(-45deg);
  transform-origin: 40% 0;
}

.menu-slide-open .content .close-menu.slidingLeft {
  animation: rotating1 1s linear infinite;
}

.menu-slide-open .content .close-menu.slidingRight {
  animation: rotating2 1s linear infinite;
}

.menu-slide-open .content .text ul {
  padding: 0;
  list-style: none;
}

.menu-slide-open .content .text ul .menu-link {
  color: #fff;
  font-family: 'Press Start 2P';
  font-size: 35px;
  transition: color 300ms ease-in-out;
}

.menu-slide-open .content .text ul .menu-link:hover {
  color: #d80027;
}

.menu-slide-open .content .text ul .menu-link.active {
  color: #d80027;
}

@keyframes rotating1 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotating2 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

@media (max-width: 1200px) {
  #contacts .content .contacts-item.lg .contact-info {
    font-size: 30px;
  }
}

@media (max-width: 992px) {
  header {
    height: 140vh;
  }

  header .menu-top .logo {
    margin: 0;
  }

  header .content .textbox h1 {
    font-size: 30px;
  }

  header .content .textbox h1 {
    text-shadow: 0px 4px 0px #d80027;
  }

  header .content .textbox h1 span {
    text-shadow: 0px 4px 0px #fff;
  }

  header .content .social {
    display: none;
  }

  header .menu-bottom .menu-link-header {
    width: 50%;
  }

  #projects .content {
    border: none;
  }

  #projects .content .grid .grid-item::before {
    width: 100%;
  }

  #projects .content .grid .grid-item .title {
    font-size: 35px;
  }

  #projects .content .grid .grid-item .title {
    transform: translateY(0);
  }

  #projects .content .grid .grid-item .project-view {
    transform: translateY(0);
  }

  #projects .content .grid .grid-item .project-view .project-actions li {
    font-size: 22px;
  }

  #projects .content .grid .grid-item .project-view .project-actions li .fa {
    display: none;
  }

  #projects .content .grid .grid-item .project-view .project-link {
    font-size: 30px;
  }

  #projects .content .grid .grid-item .project-view .project-link {
    color: #d80027;
    border-color: #d80027;
    background: rgba(0, 0, 0, 0.3);
  }

  #resume .content .center {
    width: 100%;
  }

  #resume .content .center .flipper {
    width: 80px;
    height: 80px;
  }

  #resume .content .center img {
    display: none;
  }

  #resume .content .center h4 {
    height: auto;
  }

  #resume .content .center h5 {
    height: auto;
  }

  #contacts .content .contacts-item.lg {
    width: 100%;
  }

  h3 {
    font-size: 35px;
  }

  .menu-slide-open {
    width: 100%;
  }

  .menu-slide-open .content .text ul .menu-link {
    font-size: 30px;
  }
}

@media (max-width: 992px) and (max-width: 100px) {
  #contacts .content .contacts-item div {
    column-gap: 1px;
  }
}

@media (max-width: 768px) {
  header .content .textbox h1 {
    font-size: 25px;
  }

  #about .content .featurebox {
    width: 50%;
  }

  #about .content .featurebox {
    margin-bottom: 20px;
  }

  #about .content .featurebox .svg-wrap {
    margin-bottom: 0;
  }

  #about .content .featurebox h4 {
    font-size: 20px;
  }

  #projects .content .grid .grid-item .title {
    font-size: 30px;
  }

  #projects .content .grid .grid-item .project-view .project-link {
    font-size: 25px;
  }

  #contacts .content .contacts-item {
    width: 50%;
  }

  h3 {
    font-size: 30px;
  }

  section .title + .content {
    margin: 50px 0;
  }
}

@media (max-width: 576px) {
  header .content .textbox h1 {
    font-size: 20px;
  }

  header .content .textbox h1 {
    text-shadow: 0px 3px 0px #d80027;
  }

  header .content .textbox h1 span {
    text-shadow: 0px 3px 0px #fff;
  }

  header .content .textbox h1 span {
    display: block;
  }

  #projects .content .grid .grid-item {
    background: none;
  }

  #projects .content .grid .grid-item .title {
    font-size: 25px;
  }

  #projects .content .grid .grid-item .project-view .project-actions li {
    font-size: 18px;
  }

  #projects .content .grid .grid-item .project-view .project-link {
    font-size: 20px;
  }

  #resume .content .center .flipper {
    display: none;
  }

  #resume .content .center .info {
    text-align: center;
  }

  #resume .content .center .info.cv {
    margin-top: 20px;
  }

  #resume .content .center h4 {
    flex-basis: 100%;
  }

  #resume .content .center h4 {
    text-align: right;
  }

  #resume .content .center h5 {
    flex-basis: 100%;
  }

  #contacts .content .contacts-item a .contact-info {
    font-size: 17px;
  }

  #contacts .content .contacts-item.lg .contact-info {
    font-size: 25px;
  }

  h3 {
    font-size: 25px;
  }
}

@media (max-width: 400px) {
  header {
    height: 180vh;
  }

  header .content .textbox h1 {
    font-size: 16px;
  }

  header .content .textbox h1 {
    text-shadow: 0px 2px 0px #d80027;
  }

  header .content .textbox h1 span {
    text-shadow: 0px 2px 0px #fff;
  }

  header .menu-bottom .menu-link-header {
    width: 100%;
  }

  header .menu-bottom .menu-link-header .text {
    color: #fff;
  }

  #projects .content .grid .grid-item .title {
    font-size: 20px;
  }

  #projects .content .grid .grid-item .project-view .project-link {
    font-size: 20px;
  }

  #contacts .content .contacts-item.lg .contact-info {
    font-size: 20px;
  }

  h3 {
    font-size: 25px;
  }
}