
header {
    height: 120vh;
    .lg(height, 140vh);
    .media(height, 180vh, 400px);
    
    #particles-js {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 13;
        overflow-y: hidden;
    }
    .container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .menu-top {
        padding: 12px 0;
        position: absolute;
        top: 0;
        width: 100%;
        .logo {
            position: relative;
            z-index: 15;
            display: inline-block;
            margin: 30px 0;
            .lg(margin, 0);
            height: 50px;
            img {
                max-width: none;
                height: 100%;
                width: auto;
            }
        }
        .menu-slide{
            position: relative;
            z-index: 21;
            width: 55px;
            height: 55px;
            padding: 15px 0;
            display: inline-flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            transition: background 500ms ease-in;
            &:hover {
                span{
                    transform: rotate3d(0, 1, 0.1, 180deg);
                    &:nth-child(1) {
                        transition: 0.2s;
                    }
                    &:nth-child(2) {
                        transition: 0.3s;
                    }
                    &:nth-child(3) {
                        transition: 0.4s;
                    }
                }
            }
            span {
                width: 35px;
                height: 2px;
                background: #fff;
            }
        }
    }
    .content {
        height: 100vh;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 4;
        .textbox {
            position: relative;
            z-index: 12;
            text-align: right;
            h1 {
                color: #fff;
                text-shadow: 0px 5px 0px#d80027;
                font-size: 40px;
                .fsz(30px, 25px, 20px, 16px);
                .lg(text-shadow, 0px 4px 0px#d80027);
                .sm(text-shadow, 0px 3px 0px#d80027);
                .media(text-shadow, 0px 2px 0px#d80027, 400px);
                span {
                    color: #d80027;
                    text-shadow: 0px 5px 0px#fff;
                    .lg(text-shadow, 0px 4px 0px#fff);
                    .sm(text-shadow, 0px 3px 0px#fff);
                    .media(text-shadow, 0px 2px 0px#fff, 400px);
                    .sm(display, block);
                }
            }
        }
        .social {
            .lg(display, none);
            position: absolute;
            top: 40%;
            left: 5%;
            z-index: 15;
            padding: 0;
            list-style: none;
            li {
                margin-bottom: 20px;
                &:last-child {
                    margin: 0;
                }
                &:hover {
                    .social-link span {
                        transform: scale(1);
                    }
                }
                .social-link {
                    color: rgba(216, 0, 39, 0.5);
                    font-size: 25px;
                    text-align: left;
                    span {
                        font-size: 16px;
                        display: inline-block;
                        font-family: 'Titillium Web', sans-serif;
                        transform: scale(0);
                        transform-origin: -30% 50%;
                        transition: 700ms ease-out;
                        color: @white;
                    }
                }
            }
        }
    }
    .menu-bottom {
        display: flex;
        width: 100%;
        position: relative;
        z-index: 25;
        flex-wrap: wrap;
        .menu-link-header {
            display: flex;
            align-items: center;
            width: 25%;
            .lg(width, 50%);
            height: 20vh;
            .media(width, 100%, 400px);
            background: transparent;
            transform-style: preserve-3d;
            position: relative;
            text-align: center;
            &::after{
                content: "";
                display: block;
                width: 2px;
                height: 50px;
                margin: auto;
                background: #fff;
            }
            &::before{
                content: "";
                display: block;
                width: 2px;
                height: 50px;
                margin: auto;
                background: #fff;
            }
            .text {
                margin: auto;
                transform: translateZ(20px);
                color: @red;
                font-size: 35px;
                text-transform: uppercase;
                .media(color, #fff, 400px);
                
            }
        }
    }
}
@keyframes shake {
    0% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(5px);
    }
    50% {
        transform: translateX(0px);
    }
    75% {
        transform: translateX(-5px)
    }
    100% {
        transform: translateX(0px);
    }
}
//SPACE EFFECT
@keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@-webkit-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@-moz-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@-ms-keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

.stars,
.twinkling {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

.stars {
    background: #000 url(../img/stars.png) repeat top center;
    z-index: 0;
}

.twinkling {
    background: transparent url(../img/twinkling.png) repeat top center;
    z-index: 1;
    -moz-animation: move-twink-back 200s linear infinite;
    -ms-animation: move-twink-back 200s linear infinite;
    -o-animation: move-twink-back 200s linear infinite;
    -webkit-animation: move-twink-back 200s linear infinite;
    animation: move-twink-back 200s linear infinite;
}