#about {
    background: @red ;
    min-height: 100vh;
    .title {
        width: 100%;
        text-align: center;
    }
    h5 {
        font-family: 'Permanent Marker';
    }
    .content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .featurebox {
            width: 25%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .md(width, 50%);
            .md(margin-bottom, 20px);
            
            
            &:hover {
                .svg-wrap {
                    animation: float 2s ease-in-out infinite;
                    
                }
                h4 {
                    text-shadow: 1px 3px 10px #000;
                }
            }
            
            
            
            .svg-wrap {
                width: 55%;
                margin: auto;
                margin-bottom: 10px;
                .md(margin-bottom, 0);
                
                
            }
            h4 {
                text-transform: uppercase;
                font-size: 25px;
                .md-block( {
                    font-size: 20px;
                });
                font-weight: 700;
                margin-top: auto;
                margin-bottom: 0;
                transition: 1s;
            }
        }
    }
    .use {
        .featurebox {
            margin-bottom: 40px;
        }
    }
    .get {
        .featurebox {
            transition-timing-function: linear;
            &:hover {
                h4 {
                    color: #fff;
                    text-shadow: 1px 3px 10px #000;
                }
            }

        }
        h4{
            color: #000;
            transition: color 500ms ease-in-out, text-shadow 1s ease-in-out;
        }
    }
    .svgtitle {
        display: none;
    }
}


@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}