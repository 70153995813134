#resume {
    background: @red;
    .title {
        text-align: center;
    }
    .content {
        .center {
            margin: auto;
            width: 80%;
            .lg(width, 100%);
            padding: 30px 50px;
            background: #000;
            border-radius: 50px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            position: relative;
            overflow: hidden;
            .flipper{
                .sm(display, none);
                .lg-block({
                    width: 80px;
                    height: 80px;
                });
                position: absolute;
                bottom: -5%;
                right: -5%;
                height: 100px;
                width: 100px;
                transform: rotate(15deg);
                background: #fff;
                z-index: 15;
                transition: 500ms ease-in-out;
                cursor: pointer;
                &:hover {
                    transform: rotate(0deg);
                    bottom: -3%;
                    right: -3%;
                }
            }
            
            .res-link{
                color: #fff;
                &:hover {
                    color: @red;
                }
            }
            img {
                position: absolute;
                top: 10%;
                right: 10%;
                width: 100px;
               .lg(display, none);
            }
            .info {
                width: 100%;
                .sm(text-align, center);
                text-decoration: underline;
                &.cv{
                    .sm(margin-top, 20px);
                }
                h2 {
                    text-transform: uppercase;
                    font-size:  30px;
                    
                }
            }
            .res {
                display: flex;
                justify-content: center;    
                width: 50%;
                height: 60px;
                line-height: 60px;
                color: #fff;
                text-align: center;
                font-size: 25px;
                transition: color 500ms ease-in-out;
                z-index: 2;
                &:hover {
                    color: @red;
                }
            }
            h4 {
                display: inline-block;
                flex-basis: 50%;
                height: 80px;
                .lg(height, auto);
                .sm(flex-basis, 100%);
                font-size: 22px;
                .sm(text-align, right);
                &.res-title {
                    font-size: 35px;
                }
            }
            h5{
                display: inline-block;
                flex-basis: 50%;
                height: 80px;
                .lg(height, auto);
                .sm(flex-basis, 100%);
                color: rgba(255, 255, 255, 0.7)
            }
        }
    }
}