#contacts {
    background: @black;
    padding-bottom: 10px;
    .title {
        text-align: center;
        h5{
            color: #fff;
            font-size: 15px;
            p{
                margin-bottom: 5px;
            }
            .fa{
                font-size: 20px;
                color: @red;
            }
        }        
    }
    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .contacts-item {
            width: 33.3333333333%;
            .md(width, 50%);
            text-align: center;
            font-size: 70px;
            a {
                color: #fff;
                .contact-info {
                    display: block;
                    margin: auto;
                    border-radius: 30px;
                    width: 0%;
                    background: #fff;
                    font-size: 25px;
                    .sm(font-size, 17px);
                    color: #000;
                    transition: 700ms ease-in-out;
                }
            }
            &.lg {
                width: 50%;
                .lg(width, 100%);
                font-size: 100px;
                margin-bottom: 60px;
                .contact-info{
                    transition: width 1500ms ease-in-out;
                    font-size: 35px;
                    .sm(font-size, 25px);
                    @media (max-width: 1200px) {
                        font-size: 30px;
                    }
                    @media (max-width: 400px) {
                        font-size: 20px;
                    }
                }
                :hover {
                    .fa {
                        animation: bounce 1s infinite;
                    }
                }
            }
            @media (max-width: 992px) and (max-width: 100px) {
               div {
                   column-gap: 1px;
               }
            };
            &.sm:hover {
                .contact-info {
                    width: 90%;
                }
                .fa {
                    animation: float 1s infinite;
                }
            }
        }
    }
}