@import './params/mix.less';
@import './params/var.less';
@import './header.less';
@import './about.less';
@import './projects.less';
@import './resume.less';
@import './contacts.less';
.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.pace-inactive {
    display: none;
}

.pace .pace-progress {
    background: #d80027;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px;
}

.loader {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 1999;
    .content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .img {
            width: 200px;
        }
        .text {
            margin: 20px 0;
            font-size: 50px;
            font-weight: 700;
            width: 150px;
            height: 150px;
            line-height: 145px;
            text-align: center;
            position: relative;
            .loadborder {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                border-radius: 50%;
                border: 5px solid #fff;
                border-top-color: @red;
                border-bottom-color: @red;
                animation: rotating1 3s linear infinite;
            }
        }
    }
}

::-webkit-scrollbar {
    width: 5px;
}

;
::-webkit-scrollbar-track {
    background: #000;
}

::-webkit-scrollbar-thumb {
    background: @red;
}

img {
    max-width: 100%;
    height: auto;
}

body {
    min-width: 320px;
    max-width: 100vw;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 900;
    font-size: 14px;
    color: #fff;
}

html,body {
    overflow-x: hidden;
}

h1,
h3 {
    font-family: 'Press Start 2P', cursive;
}

h4,
h5 {
    font-family: 'Titillium Web';
}

h3 {
    font-size: 40px;
    .fsz(35px, 30px, 25px, 25px);
    text-shadow: 1px 3px 4px #000;
}

a:hover {
    text-decoration: none;
}

section {
    min-height: 100vh;
    padding: 100px 0 70px 0;
    .title+.content {
        width: 100%;
        margin: 80px 0;
        .md(margin, 50px 0);
    }
}

.menu-slide-open {
    position: fixed;
    .lg(width, 100%);
    height: 100vh;
    top: 0;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.97);
    .content {
        position: absolute;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .close-menu {
            position: absolute;
            top: 5%;
            right: 5%;
            width: 25px;
            height: 25px;
            z-index: 1002;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span {
                display: block;
                width: 30px;
                height: 2px;
                background: #fff;
                &:nth-child(1) {
                    transform: rotate(45deg);
                    transform-origin: 50% 0;
                }
                &:nth-child(2) {
                    transform: rotate(-45deg);
                    transform-origin: 40% 0;
                }
            }
            &.slidingLeft {
                animation: rotating1 1s linear infinite;
            }
            &.slidingRight {
                animation: rotating2 1s linear infinite;
            }
        }
        .text {
            ul {
                padding: 0;
                list-style: none;
                .menu-link {
                    color: #fff;
                    font-family: 'Press Start 2P';
                    font-size: 35px;
                    .lg(font-size, 30px);
                    transition: color 300ms ease-in-out;
                    &:hover {
                        color: @red;
                    }
                    &.active {
                        color: @red;
                    }
                }
            }
        }
    }
}

@keyframes rotating1 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes rotating2 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}